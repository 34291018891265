import dynamic from 'next/dynamic';

const LandingView = dynamic(() =>
  import('features/landing/landing-view').then((mod) => mod.LandingView),
);

const IndexPage = () => {
  return <LandingView />;
};

export { getServerSideProps } from 'getServerSideProps';
export default IndexPage;
